
main {
    margin-bottom: 160px;
}

input.chakra-input {
    background: #f1f0f4;
    border-radius: 6px;
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

input.phone-input {
    background: #FFFFFF;
    border: 1px solid #D6D9D4;
    border-radius: 6px;
    height: 3rem;
    width: 100%;
    padding: 1rem;
}

input.invalid {
    border: 1px solid #ff0000
}

button.user-menu>span.chakra-button__icon {
 color: #F16158
}

svg.slider-icon {
    position: relative;
    top:-20px
}
